<template>
  <div class="contract-modal">
    <sweet-modal ref="modal" :title="title" overlay-theme="dark" blocking @close="onClose">
      <div class="contract-modal-content" v-if="visible">
        <div>
          <div class="label">총 계약금<span class="required">*</span></div>
          <input-price-num style="width: 100%" :value.sync="value.price" :maxLength="50"></input-price-num>
          <lp-check-bool style="margin-top: 8px" :checkedVal.sync="value.vat" label="부가세 포함"></lp-check-bool>
        </div>
        <div>
          <div class="label">계약기간<span class="required">*</span></div>
          <div class="flex-align" style="gap: 8px">
            <input-date style="flex: 1" :defaultNow="false" :value.sync="value.start_date" :maxLength="50"></input-date>
            <div>~</div>
            <input-date style="flex: 1" :defaultNow="false" :value.sync="value.end_date" :maxLength="50"></input-date>
          </div>
        </div>
        <div>
          <div class="label">선금/중도금/잔금<span class="required">*</span></div>
          <div class="flex" style="gap: 8px; margin-top: 16px">
            <div style="flex: 1">
              <div class="input-label">선금 지급일</div>
              <input-date :defaultNow="false" :value.sync="value.deposit_date" :maxLength="50"></input-date>
            </div>
            <div style="flex: 1">
              <div class="input-label">금액</div>
              <input-price-num :value.sync="value.deposit" :maxLength="50"></input-price-num>
            </div>
          </div>
          <div class="flex" style="gap: 8px; margin-top: 16px">
            <div style="flex: 1">
              <div class="input-label">중도금 지급일</div>
              <input-date :defaultNow="false" :value.sync="value.intermediate_date" :maxLength="50"></input-date>
            </div>
            <div style="flex: 1">
              <div class="input-label">금액</div>
              <input-price-num :value.sync="value.intermediate" :maxLength="50"></input-price-num>
            </div>
          </div>
          <div class="flex" style="gap: 8px; margin-top: 16px">
            <div style="flex: 1">
              <div class="input-label">잔금 지급일</div>
              <input-date :defaultNow="false" :value.sync="value.balance_date" :maxLength="50"></input-date>
            </div>
            <div style="flex: 1">
              <div class="input-label">금액</div>
              <input-price-num :value.sync="value.balance" :maxLength="50"></input-price-num>
            </div>
          </div>
        </div>
        <div>
          <div class="label">계약내용<span class="required">*</span></div>
          <lp-text-area
            :value.sync="value.content"
            placeholder="계약내용을 입력해주세요."
            :maxLength="10000"></lp-text-area>
        </div>
        <div>
          <div class="label">계약서 파일</div>
          <input-file :value.sync="value.file" class="input-file" description="" :maxLength="1"></input-file>
        </div>
        <button v-if="!id" class="button is-primary" @click="reg">완료</button>
        <button v-else class="button is-primary" @click="save">수정 완료</button>
      </div>
    </sweet-modal>
  </div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
import InputPriceNum from '../module/InputPriceNum';
import LpTextArea from './LpTextArea';
import InputDate from '../module/InputDate';
import InputFile from '../module/InputFile';
import LpCheckBool from '../module/LpCheckBool';
import PartnerAPIMixin from '../../mixins/PartnerAPIMixin';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
export default {
  name: 'ContractRegModal',
  components: {
    SweetModal,
    InputPriceNum,
    LpTextArea,
    InputDate,
    LpCheckBool,
    InputFile,
  },
  mixins: [PartnerAPIMixin],
  props: {},
  data() {
    return {
      id: null,
      visible: false,
      value: {
        price: 0, // 총 계약금
        vat: false, // 부가세 포함 여부
        start_date: null, // 계약 시작일
        end_date: null, // 계약 종료일
        deposit: 0, // 선금
        intermediate: 0, // 중도금
        balance: 0, // 잔금
        deposit_date: null, // 선금 납입일
        intermediate_date: null, // 중도금 납입일
        balance_date: null, // 잔금 납입일
        content: '', // 계약 내용
        file: [], // 계약서 파일
      },
    };
  },
  computed: {
    title() {
      return this.id ? '계약 수정하기' : '계약 요청하기';
    },
  },
  watch: {},
  mounted() {},
  methods: {
    init() {
      this.value = {
        price: 0, // 총 계약금
        vat: false, // 부가세 포함 여부
        start_date: null, // 계약 시작일
        end_date: null, // 계약 종료일
        deposit: 0, // 선금
        intermediate: 0, // 중도금
        balance: 0, // 잔금
        deposit_date: null, // 선금 납입일
        intermediate_date: null, // 중도금 납입일
        balance_date: null, // 잔금 납입일
        content: '', // 계약 내용
        file: [], // 계약서 파일
      };
    },
    open(id) {
      this.visible = false;
      this.id = id;
      this.init();
      if (id) {
        this.getData();
      } else {
        this.visible = true;
      }
      this.$refs.modal.open();
    },
    onClose() {
      setTimeout(() => {
        this.visible = false;
        this.init();
      }, 500);
    },
    getData() {
      this.request.partner.get(`launchpack/contract/${this.id}`).then((res) => {
        res.data.file = res.data.file ? [res.data.file] : [];
        this.value = res.data;
        this.visible = true;
      });
    },
    isValid() {
      let msg = '';
      if (this.value.price <= 0) {
        msg = '총 계약금을 입력해주세요.';
      }
      if (msg === '' && this.value.start_date === null) {
        msg = '계약 시작일을 선택해주세요.';
      }
      if (msg === '' && this.value.end_date === null) {
        msg = '계약 종료일을 선택해주세요.';
      }
      if (msg === '' && this.value.deposit_date === null) {
        msg = '선금 납입일을 선택해주세요.';
      }
      if (msg === '' && this.value.balance_date === null) {
        msg = '잔금 납입일을 선택해주세요.';
      }
      if (msg === '' && this.value.deposit === 0) {
        msg = '선금을 입력해주세요.';
      }
      if (msg === '' && this.value.balance === 0) {
        msg = '잔금을 입력해주세요.';
      }
      if (msg === '' && this.value.deposit + this.value.intermediate + this.value.balance !== this.value.price) {
        msg = '선금, 중도금, 잔금의 합계가 총 계약금과 일치하지 않습니다.';
      }
      if (msg === '' && this.value.content.trim() === '') {
        msg = '계약 내용을 입력해주세요.';
      }
      if (msg) {
        this.toast(msg);
        return false;
      }
      return true;
    },
    save() {
      if (!this.isValid()) return;
      let params = this.cloneItem(this.value);
      params.inquiry_id = this.$route.query.id;
      params.file = this.value.file.length > 0 ? this.value.file[0] : null;
      this.setLoading();
      this.request.partner
        .patch(`launchpack/contract/${this.id}`, params)
        .then(() => {
          this.clearLoading();
          this.$refs.modal.close();
        })
        .catch(() => {
          this.clearLoading();
          this.toast('계약 수정에 실패했습니다.');
        });
    },
    reg() {
      if (!this.isValid()) return;
      let params = this.cloneItem(this.value);
      params.inquiry_id = this.$route.query.id;
      params.file = this.value.file.length > 0 ? this.value.file[0] : null;
      this.setLoading();
      this.request.partner
        .post('launchpack/contract', params)
        .then((res) => {
          this.clearLoading();
          this.$refs.modal.close();
          params.id = res.data.id;
          params.status = 0;
          this.$emit('reg', params);
        })
        .catch(() => {
          this.clearLoading();
          this.toast('계약 등록에 실패했습니다.');
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.contract-modal-content
  display flex
  flex-direction column
  gap 24px
  .label
    font-size 15px
    font-weight 500
    margin-bottom 8px
  .button
    width 100%
    height 52px
    font-size 16px
    font-weight 700
    line-height 24px
  .required
    color red
  .input-label
    font-size 15px
    font-weight 400
    margin-bottom 4px
    color $sub2

.button
  margin-top 16px
.input-file
  // max-width 380px
@media (max-width: 600px)
  .contract-modal
    .input-file
      max-width calc(100vw - 32px)
</style>

<style lang="stylus">
.contract-modal
  .sweet-modal .sweet-title
    border-bottom 0
    padding 16px
    height fit-content
  .sweet-modal .sweet-title > h2
    font-size 18px
    font-weight 700
    line-height 24px
  .sweet-modal .sweet-box-actions
    top 4px
  .sweet-modal .sweet-buttons, .sweet-modal .sweet-content
    padding 16px
@media (min-width: 601px)
  .contract-modal
    .sweet-modal
      width 430px
      max-height 90vh
      overflow hidden
    .sweet-modal .sweet-content
      max-height calc(90vh - 68px)
      overflow-y auto

@media (max-width: 600px)
  .contract-modal .sweet-modal .sweet-content .sweet-content-content
    padding-bottom 80px
  .sweet-modal
    width 100%
      border-radius 0 !important
</style>
