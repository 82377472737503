<template>
  <div class="input-price">
    <input
        type="text"
        v-model.number="inputValue"
        @input="onInput"
      />
      <div class="input-price-endfix">원</div>
  </div>
</template>
<script>
export default {
  name: "InputPriceNum",
  components: {},
  mixins: [],
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      inputValue: 0,
    };
  },
  created() {
    this.inputValue = this.value;
  },
  computed: {},
  watch: {
    value(n) {
      this.inputValue = n;
    },
  },
  methods: {
    selectAll(e) {
      e.target.select();
    },
    onInput(e) {
      this.$emit("update:value", Number(e.target.value));
    },
  },
};
</script>
<style lang="stylus" scoped>
.input-price
  position relative
input
  border 1px solid #e3e3e3
  border-radius 8px
  padding 12px 16px
  outline none
  font-size 15px
  color #333
  width 100%
  height 52px
  line-height 52px
  text-align right
  padding-right 32px
.input-price-endfix
  position absolute
  font-size 15px
  right 16px
  top 50%
  transform translateY(-50%)
  color #333

</style>
